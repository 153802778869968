export default function fancybox() {
    if (document.querySelector(".accommodations__slider")) {
        let tabSlides = document.querySelectorAll(".tab-content-item");
        for (let i = 1; i < tabSlides.length+1; i++) {
            Fancybox.bind(`[data-fancybox='gallery-${i}']`, {
                Thumbs: {
                    type: "classic",
                },
            });
        }
    }

    if(document.querySelector("[data-fancybox='gallery']")){
        Fancybox.bind("[data-fancybox='gallery']", {
            Thumbs: {
                type: "classic",
            },
        });
        Fancybox.bind("[data-fancybox='gallery-video']", {
            Thumbs: {
                type: "classic",
            },
        });
    }

    if (document.querySelector(".page-citron")) {
        let depoiments = document.querySelectorAll(".depoiments__slider .swiper-slide");
        let arrNumberDepoiment = [];

        depoiments.forEach(item => {
            let numberDepoiment = item.dataset.depoiment;

            if (!arrNumberDepoiment.includes(numberDepoiment)) {
                arrNumberDepoiment.push(numberDepoiment);
            }
        });

        arrNumberDepoiment.forEach(number => {
            console.log(number);
            Fancybox.bind(`[data-fancybox='gallery-${number}']`, {
                Thumbs: {
                    type: "classic",
                },
            });
        });
    }
}
