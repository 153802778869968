export default function depoimentSeeMore() {
    if (document.querySelector(".depoiments__slider")) {
        const depoiments = document.querySelectorAll(".depoiment__description > p");
        const seeMore = document.querySelectorAll(".depoiment__description > div");

        seeMore.forEach(btn => {
            btn.addEventListener("click", () => {
                btn.classList.toggle("open");

                btn.parentElement.children[0].classList.toggle("active");
            });
        });
    }
}
