export default function bookingEngine() {
    $(function () {
        $("#dpd1").datepicker({
            dateFormat: "dd-mm-yy",
            minDate: 0,
            onSelect: function (e) {
                let o = $("#dpd1").datepicker("getDate");
                o.setDate(o.getDate() + 1);
                $("#dpd2").datepicker("setDate", o);
                $("#dpd2").datepicker("option", "minDate", o);
            },
        }).attr("readonly", "readonly").datepicker("setDate", new Date);
        $("#dpd2").datepicker({
            dateFormat: "dd-mm-yy",
            onClose: function () {
                let e = $("#dpd1").datepicker("getDate");
                if (console.log(e),
                $("#dpd2").datepicker("getDate") <= e) {
                    let o = $("#dpd2").datepicker("option", "minDate");
                    $("#dpd2").datepicker("setDate", o);
                }
            },
        }).attr("readonly", "readonly").datepicker("setDate", "1"),
        $(".button").click(function () {
            $("#dpd1").val($("#dpd1").val().replace(/\//g, ""));
            $("#dpd2").val($("#dpd2").val().replace(/\//g, ""));
        });

        $("#ch").on("change keyup blur", function(){
            let a, e = $(this).val();
            let idade;
            e > 0 && $("#box-crianca").addClass("ativa"),
            e < 1 && ($("#box-crianca").removeClass("ativa"),
            $("#ag").removeClass("ativa"),
            $("#ag").addClass("esconde")),
            $("#output").empty();
            for (let o = 0, t = e; o < t; o++) idade = o + 1, a = "<label class=\"crianca\">Criança " + idade + ": </label>  <input type=\"number\" class=\"idade\" min=\"1\" max=\"12\" value=\"1\" min=\"1\" max=\"12\" id=\"ag" + o + "\"/><br>", $("#output").append(a);
        });

        let $salvarIdade = $("#salvar-idade");

        $salvarIdade.click(function() {
            let a = "";
            let e = $("#ch").val();
            e = +e;
            for (let d = 0; d < e; d++) $("#ag" + d).val() && d !== e && (a += $("#ag" + d).val() + ";");
            $("#output").removeClass("esconde");
            $("#box-crianca").removeClass("ativa");
            $("#box-crianca").addClass("esconde");
            let o = a.slice(0, -1);
            $("#ag").val(o);
        });

        // Adicione a formatação da data antes do envio do formulário
        $("#be form").submit(function() {
            let inputData = $("#dpd1").val();
            let regex = /^(\d{2})-(\d{2})-(\d{4})$/;
            let inputData2 = $("#dpd2").val();
            if (regex.test(inputData)) {
                let partesData = inputData.match(regex);
                let dataFormatada = partesData[1] + partesData[2] + partesData[3];
                $("#dpd1").val(dataFormatada);

                setTimeout(() => {
                    $("#dpd1").val(inputData);
                }, 100);
            }
            if (regex.test(inputData2)) {
                let partesData2 = inputData2.match(regex);
                let dataFormatada2 = partesData2[1] + partesData2[2] + partesData2[3];
                $("#dpd2").val(dataFormatada2);

                setTimeout(() => {
                    $("#dpd2").val(inputData2);
                }, 100);
            }
        });
    });

    if(document.querySelector(".modal-column--children")) {
        let addReserv = document.querySelector(".modal-column--children");
        let qtdRooms = document.querySelector("#NRooms");
        let qtdAdults = document.querySelector("#ad");
        let qtdChildren = document.querySelector("#ch");
        let resumeReserv = document.querySelector("#be form .row .columns p:first-of-type");

        addReserv.addEventListener("click", () => {
            resumeReserv.innerHTML = `${qtdRooms.selectedOptions[0].text} Quartos, ${qtdAdults.selectedOptions[0].text} Adultos, ${qtdChildren.selectedOptions[0].text} Crianças`;
        });
    }
}
