import { Swiper, Navigation, Pagination, Scrollbar, Thumbs } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Scrollbar, Thumbs]);

export default () => {
    let swiper = new Swiper(".gallery-modal-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let swiperHotel = new Swiper(".hotel__slide", {
        navigation: {
            nextEl: ".hotel__next",
            prevEl: ".hotel__prev",
        },
    });

    let swiperAwards = new Swiper(".awards__slides", {
        slidesPerView: 4,
        spaceBetween: 24,
        navigation: {
            nextEl: ".awards__next",
            prevEl: ".awards__prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
        },
    });

    let swiperBlogBanner = new Swiper(".blog-banner", {
        navigation: {
            nextEl: ".banner__next",
            prevEl: ".banner__prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let swiperLpBanner = new Swiper(".lp-banner", {
        navigation: {
            nextEl: ".banner__next",
            prevEl: ".banner__prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let swiperCategory = new Swiper(".category__slider", {
        slidesPerView: 4.2,
        spaceBetween: 17,
        scrollbar: {
            el: ".swiper-scrollbar",
        },
        breakpoints: {
            768: {
                slidesPerView: 2.2,
                spaceBetween: 24,
            },
            375: {
                slidesPerView: 1,
                spaceBetween: 24,
            },
        },
    });

    let swiperBlog = new Swiper(".blog__slider", {
        navigation: {
            nextEl: ".blog__next",
            prevEl: ".blog__prev",
        },
    });

    let depoiments = new Swiper(".depoiments__slider", {
        watchOverflow: true,
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            nextEl: ".depoiment-button-next",
            prevEl: ".depoiment-button-prev",
        },
        pagination: {
            el: ".depoiment-pagination",
            type: "bullets",
            clickable: true,
        },
        breakpoints: {
            600: {
                slidesPerView: 1.2,
                centeredSlides: false,
                spaceBetween: 20,
            },
        },
    });

    let acomodationSlider = new Swiper(".acomodation__slider", {
        watchOverflow: true,
        navigation: {
            nextEl: ".acomodation-button-next",
            prevEl: ".acomodation-button-prev",
        },
        slidesPerView: 2,
        spaceBetween: 20,
        breakpoints: {
            900: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            600: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    });

    let swiperEvent = new Swiper(".event__slide", {
        navigation: {
            nextEl: ".event__next",
            prevEl: ".event__prev",
        },
    });

    let experienceSlider = new Swiper(".experience__slider", {
        watchOverflow: true,
        navigation: {
            nextEl: ".experience-button-next",
            prevEl: ".experience-button-prev",
        },
        slidesPerView: 3,
        spaceBetween: 32,
        breakpoints: {
            900: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
            600: {
                slidesPerView: 1,
                spaceBetween: 32,
            },
        },
    });

    let wellbeing = new Swiper(".wellbeing__slider", {
        watchOverflow: true,
        navigation: {
            nextEl: ".wellbeing-button-next",
            prevEl: ".wellbeing-button-prev",
        },
    });

    let accommodationsTabs = document.querySelectorAll(".accommodations .tab-header-item");
    let accommodationsItems = document.querySelectorAll(".accommodations .tab-content-item");

    accommodationsItems.forEach(item => {
        if (item.classList.contains("is-active")) {
            let accommodationsThumbs = new Swiper(item.children[1], {
                width: 600,
                spaceBetween: 10,
                slidesPerView: 8,
                thumbsContainerClass: ".swiper-thumbs",
                slideThumbActiveClass: "swiper-slide-thumb-active",
                watchSlidesProgress: true,
                allowTouchMove: false,
                navigation: {
                    nextEl: ".accommodations-button-next",
                    prevEl: ".accommodations-button-prev",
                },
            });

            let accommodations = new Swiper(item.children[0], {
                watchOverflow: true,
                spaceBetween: 30,
                navigation: {
                    nextEl: ".accommodations-button-next",
                    prevEl: ".accommodations-button-prev",
                },
                thumbs: {
                    swiper: accommodationsThumbs,
                },
            });
        }
    });

    accommodationsTabs.forEach(tab => {
        tab.addEventListener("click", () => {
            let accommodationsItems1 = document.querySelectorAll(".tab-content-item");

            accommodationsItems1.forEach(item => {
                if (item.classList.contains("is-active")) {
                    let accommodationsThumbs = new Swiper(item.children[1], {
                        width: 600,
                        spaceBetween: 10,
                        slidesPerView: 8,
                        thumbsContainerClass: ".swiper-thumbs",
                        slideThumbActiveClass: "swiper-slide-thumb-active",
                        watchSlidesProgress: true,
                        allowTouchMove: false,
                        navigation: {
                            nextEl: ".accommodations-button-next",
                            prevEl: ".accommodations-button-prev",
                        },
                    });

                    let accommodations = new Swiper(item.children[0], {
                        watchOverflow: true,
                        spaceBetween: 30,
                        navigation: {
                            nextEl: ".accommodations-button-next",
                            prevEl: ".accommodations-button-prev",
                        },
                        thumbs: {
                            swiper: accommodationsThumbs,
                        },
                    });
                }
            });
        });
    });
};
